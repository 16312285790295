import {
  HeartIcon,
  ArrowLeftOnRectangleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useSession } from "next-auth/react";
import Link from "next/link";

export default function WatchlistEmptyState({
  isFavoriteSearch,
}: {
  isFavoriteSearch?: boolean;
}) {
  const { status: sessionStatus } = useSession();

  const unauthenticated = sessionStatus === "unauthenticated";

  const Icon = isFavoriteSearch ? MagnifyingGlassIcon : HeartIcon;
  const title = isFavoriteSearch
    ? "Aún no tenés búsquedas favoritas"
    : "Aún no tenés favoritos";
  const description = isFavoriteSearch
    ? "Podés guardar las búsquedas que hagás en el listado para poder realizarlas con mayor facilidad."
    : "Empezá por agregar vehículos a tu lista de favoritos.";

  return (
    <div className="text-center pt-8 ">
      <div className=" border-2 border-gray-300 border-dashed rounded-lg py-8">
        {unauthenticated ? (
          <ArrowLeftOnRectangleIcon
            className="mx-auto h-10 w-10 text-gray-400"
            aria-hidden="true"
          />
        ) : (
          <Icon
            className="mx-auto h-10 w-10 text-gray-400"
            aria-hidden="true"
          />
        )}
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          {unauthenticated ? "Iniciá sesión" : title}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {unauthenticated
            ? `Iniciá sesión para poder guardar ${
                isFavoriteSearch
                  ? "tus búsquedas favoritas"
                  : "tus vehículos favoritos"
              }.`
            : description}
        </p>
        <div className="mt-6">
          <Link href={unauthenticated ? "/api/auth/signin" : "/autosusados"}>
            <span className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
              {unauthenticated ? "Iniciar sesión" : "Buscar Vehículos"}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
