import Head from "next/head";
import { useSession } from "next-auth/react";
import Layout from "../components/Layout";
import Grid from "../components/Grid";
import CategoryGrid from "../components/CategoryGrid";
import WatchlistEmptyState from "../components/WatchlistEmptyState";
import GoToWatchlistCard from "../components/GoToWatchlistCard";
import Link from "next/link";
import Map from "../components/Map";
import {
  useGetUserLastVisitedQuery,
  useGetUserWatchlistQuery,
  GetVehiclesQuery,
  GetVehiclesDocument,
  GetFeaturedVehiclesDocument,
  GetFeaturedVehiclesQuery,
  useGetVehiclesQuery,
} from "../graphql/generated/graphql";
import FavoriteSearches from "../components/FavoriteSearches";
import SellYourCarCTA from "../components/SellYourCarCTA";
import Hero2 from "../components/Hero2";
import SEO from "../components/SEO";
import { TIER_KEYS } from "../utils/constants";
import FollowUsNotification from "../components/FollowUsNotification";
import Banners from "../components/Banners";
import { addApolloState, initializeApollo } from "../graphql/client";
import BrandGrid from "../components/BrandGrid";
import SearchField from "../components/SearchField";
import CollectionBanner from "../components/collection/Banner";

export default function Home({ vehiclesCollection, vehicleFeatured }) {
  const { data: session, status: sessionStatus } = useSession();

  const {
    data,
    loading: loadingLatestMaxVehicles,
    // error,
  } = useGetVehiclesQuery({
    variables: {
      limit: 24,
      filters: {
        listingTier: TIER_KEYS.MAX,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const { getVehicles } = data || {};
  const { data: vehiclesMax } = getVehicles || {};

  const {
    data: dataPlus,
    loading: loadingLatestPlusVehicles,
    // error,
  } = useGetVehiclesQuery({
    variables: {
      limit: 14,
      filters: {
        listingTier: TIER_KEYS.PLUS,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const { getVehicles: getVehiclesPlus } = dataPlus || {};
  const { data: vehiclesPlus } = getVehiclesPlus || {};

  const {
    data: dataFree,
    loading: loadingLatestFreeVehicles,
    // error,
  } = useGetVehiclesQuery({
    variables: {
      limit: 14,
      filters: {
        listingTier: TIER_KEYS.FREE,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const { getVehicles: getVehiclesFree } = dataFree || {};
  const { data: vehiclesFree } = getVehiclesFree || {};

  const { data: watchlistData, loading: loadingWatchlist } =
    useGetUserWatchlistQuery({
      skip: sessionStatus !== "authenticated",
    });

  const { data: lastVisitedData, loading: loadingLastVisited } =
    useGetUserLastVisitedQuery({
      skip: sessionStatus !== "authenticated",
    });

  const unauthenticated = sessionStatus !== "authenticated";

  return (
    <div>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Head>

      <SEO
        currentURL={`${process.env.NEXT_PUBLIC_ROOT_URL}`}
        title="Movi Autos"
        siteTitle="Autos usados en Costa Rica"
      />

      {/* <Hero /> */}
      <Hero2
        renderTitle={() => (
          <h1 className="text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl">
            <span className="block xl:inline text-white lg:text-black drop-shadow-md lg:drop-shadow-none">
              Las mejores ofertas en
            </span>{" "}
            <span className="block  xl:inline">
              <span className="text-orange-500 bg-white lg:bg-transparent pb-1.5 lg:pb-none px-4 lg:px-0 rounded-md leading-relaxed lg:leading-none shadow-md lg:shadow-none">
                autos usados
              </span>
            </span>
          </h1>
        )}
        renderCTA={() => (
          <div className="max-w-3xl m-auto mt-5">
            <SearchField large></SearchField>
            <p className="hidden lg:block pt-2 text-gray-600 font-light text-sm">
              Ejemplo de búsqueda: Toyota Hilux automático 2018
            </p>
          </div>
        )}
        subtitle="Movi es el sitio web más fácil y seguro para comprar y vender autos usados en 🇨🇷"
        image="/hero_bg8.jpg"
        featuredVehicle={vehicleFeatured.getFeaturedVehicles}
      />
      {/* <p className="lg:hidden text-center mt-6 mb-6 mx-4 text-base text-gray-500 sm:mx-auto sm:max-w-xl sm:text-lg  md:text-xl lg:mx-0">
        Movi es el sitio web más seguro para buscar, comprar y vender autos
        usados en 🇨🇷
      </p> */}
      <div className="sm:mt-6">
        <Banners />
      </div>

      <main className="max-w-7xl mx-auto mt-8">
        {vehiclesCollection?.length ? (
          <Grid
            items={vehiclesCollection}
            title={<CollectionBanner />}
            LastElement={() => (
              <GoToWatchlistCard
                pathname="/collection"
                icon="list"
                label="Ver todos"
              />
            )}
          />
        ) : null}

        <Grid
          loading={loadingLatestMaxVehicles}
          items={vehiclesMax}
          title={
            <span className="">
              Últimos autos <span className="text-blue-500">MAX</span>
            </span>
          }
          LastElement={() => (
            <GoToWatchlistCard
              pathname="/autosusados"
              icon="list"
              label="Ver todos"
            />
          )}
        />

        <Grid
          loading={loadingLatestPlusVehicles}
          items={vehiclesPlus}
          title={
            <span className="">
              Últimos autos <span className="text-emerald-500">PLUS</span>
            </span>
          }
          LastElement={() => (
            <GoToWatchlistCard
              pathname="/autosusados"
              icon="list"
              label="Ver todos"
            />
          )}
        />
        <div className="my-10">
          <Banners />
        </div>
        <div className="px-4 mb-6">
          <FollowUsNotification fixed />
        </div>
        <CategoryGrid />
        <BrandGrid />
        <Map />
        {!unauthenticated && lastVisitedData?.getUserLastVisited?.length ? (
          <Grid
            loading={loadingLastVisited}
            items={lastVisitedData?.getUserLastVisited || []}
            title="Vistos recientemente"
            limit={5}
          />
        ) : null}
        <Grid
          loading={loadingWatchlist || sessionStatus === "loading"}
          items={watchlistData?.getUserWatchlist || []}
          title="Tus Autos Favoritos"
          limit={7}
          EmptyStateComponent={WatchlistEmptyState}
          LastElement={() => (
            <GoToWatchlistCard
              pathname="/favorites"
              icon="favorites"
              label="Ir a favoritos"
            />
          )}
        />
        <FavoriteSearches limit={5} />
        <SellYourCarCTA />
        <div className="mt-6">
          <Grid
            loading={loadingLatestFreeVehicles}
            items={vehiclesFree}
            title={
              <span className="">Otros autos que te podrían interesar</span>
            }
            LastElement={() => (
              <GoToWatchlistCard
                pathname="/autosusados"
                icon="list"
                label="Ver todos"
              />
            )}
          />
        </div>
        <div className="w-full mt-12 flex items-center justify-center">
          <Link href="/autosusados">
            <span className="cursor-pointer flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 md:py-4 md:text-lg md:px-10">
              Ver todos los autos usados
            </span>
          </Link>
        </div>
      </main>
    </div>
  );
}

Home.getLayout = function getLayout(page) {
  return <Layout>{page}</Layout>;
};

export const getStaticProps = async ({ params }) => {
  const apolloClient = initializeApollo({});
  try {
    const getVehiclesCollection = apolloClient.query<GetVehiclesQuery>({
      query: GetVehiclesDocument,
      variables: {
        limit: 9,
        filters: {
          listingTier: TIER_KEYS.COLLECTION,
        },
      },
    });

    // const getVehiclesMax = apolloClient.query<GetVehiclesQuery>({
    //   query: GetVehiclesDocument,
    //   variables: {
    //     limit: 24,
    //     filters: {
    //       listingTier: TIER_KEYS.MAX,
    //     },
    //     // random: true,
    //   },
    // });

    // const getVehiclesPlus = apolloClient.query<GetVehiclesQuery>({
    //   query: GetVehiclesDocument,
    //   variables: {
    //     limit: 24,
    //     filters: {
    //       listingTier: TIER_KEYS.PLUS,
    //     },
    //     random: true,
    //   },
    // });

    // const getVehiclesFreeTier = apolloClient.query<GetVehiclesQuery>({
    //   query: GetVehiclesDocument,
    //   variables: {
    //     limit: 24,
    //     filters: {
    //       listingTier: TIER_KEYS.FREE,
    //     },
    //     // random: true,
    //   },
    // });

    const getFeaturedVehicle = apolloClient.query<GetFeaturedVehiclesQuery>({
      query: GetFeaturedVehiclesDocument,
    });

    const [
      // { data },
      // { data: plusData },
      { data: collectionData },
      // { data: freeData },
      { data: vehicleFeatured },
    ] = await Promise.all([
      // getVehiclesMax,
      // getVehiclesPlus,
      getVehiclesCollection,
      // getVehiclesFreeTier,
      getFeaturedVehicle,
    ]);

    // const { getVehicles } = data || {};
    // const { data: vehiclesMax } = getVehicles || {};

    // const { getVehicles: getPlusVehicles } = plusData || {};
    // const { data: vehiclesPlus } = getPlusVehicles || {};

    const { getVehicles: getCollectionVehicles } = collectionData || {};
    const { data: vehiclesCollection } = getCollectionVehicles || {};

    //
    // const { getVehicles: getFreeVehicles } = freeData || {};
    // const { data: vehiclesFree } = getFreeVehicles || {};

    return addApolloState(apolloClient, {
      props: {
        // vehiclesMax: vehiclesMax || [],
        // vehiclesPlus: vehiclesPlus || [],
        vehiclesCollection: vehiclesCollection || [],
        vehicleFeatured: vehicleFeatured || {},
        // vehiclesFree: vehiclesFree || [],
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every 10 seconds
      revalidate: 59, // In seconds
    });
  } catch (e) {
    console.log("ERR", e);
    return {
      props: {
        // vehiclesMax: [],
        // vehiclesPlus: [],
        vehiclesCollection: [],
        vehicleFeatured: {},
        // vehiclesFree: [],
      },
      // Next.js will attempt to re-generate the page:
      // - When a request comes in
      // - At most once every 10 seconds
      revalidate: 59, // In seconds
    };
  }
};
