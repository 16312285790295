import Image from "next/image";
import { ReactNode } from "react";
import { Vehicle } from "../graphql/generated/graphql";
import { formatPrice } from "../utils/formatters";
import TierBadge from "./TierBadge";
import Link from "next/link";
import { TIER_KEYS } from "../utils/constants";

export type THeroProps = {
  subtitle?: string;
  image: string;
  featuredVehicle?: Vehicle;
  renderTitle: () => ReactNode;
  renderCTA: () => ReactNode;
};

const FeaturedVehicleComponent = ({
  featuredVehicle,
  containerClassName,
}: {
  featuredVehicle: Vehicle;
  containerClassName?: string;
}) => {
  return (
    <div className={containerClassName}>
      <div className="h-full">
        <Link href={`/autosusados/${featuredVehicle.slug}`}>
          {/* <h2 className="lg:text-2xl text-lg font-medium  ">
                  {!featuredVehicle.hidePrice ? (
                    <span className="inline-block mr-2">
                      {formatPrice(
                        featuredVehicle.price,
                        featuredVehicle.currency
                      )}
                    </span>
                  ) : null}
                </h2> */}
          <div className="absolute h-full w-full">
            <Image
              className="h-full w-full object-center object-cover"
              src={featuredVehicle.pictures[0]}
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              style={{
                objectFit: "cover",
              }}
              alt="Cover Movi Autos"
              priority
            />
            <div className="flex w-full justify-end">
              <div className="bg-gray-100 shadow absolute bottom-0 lg:bottom-2 px-5 lg:pr-20 lg:pl-8 sm:py-4 py-1 w-50 -skew-x-12 translate-x-2 -translate-y-2 lg:translate-x-14 lg:-translate-y-2">
                <div className="items-center flex justify-end gap-x-4 skew-x-12">
                  <div>
                    <h2 className="capitalize sm:text-xl text-orange-500">
                      {featuredVehicle.brand} {featuredVehicle.model}{" "}
                      {featuredVehicle.modelVersion
                        ? `${featuredVehicle.modelVersion} `
                        : ""}
                    </h2>
                    <Image
                      src="/collection_transparent_bg.png"
                      alt="Logo Movi Collection"
                      width={90}
                      height={25.2}
                    />
                  </div>
                  <div>
                    <button className="px-4 py-2 border border-transparent shadow-sm text-sm sm:text-lg font-medium rounded-md text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500">
                      Conocer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default function Hero2({
  renderTitle,
  renderCTA,
  subtitle,
  image,
  featuredVehicle,
}: THeroProps) {
  return (
    <>
      <div className="relative overflow-hidden bg-gray-100  ">
        <div className="mx-auto lg:max-w-7xl">
          <div className="relative z-10 lg:bg-gray-100 pb-8 sm:pb-16 md:pb-20 lg:w-1/2 lg:max-w-2xl lg:pb-28 xl:pb-32 bg-cover bg-center bg-no-repeat lg:bg-none">
            <svg
              className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 transform text-gray-100 lg:block"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>
            <main className="mx-auto pt-10 max-w-7xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
              <div className="sm:text-center lg:text-left">
                {renderTitle()}
                <p className="relative hidden lg:block mt-3 text-base text-gray-600 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0 z-10 ">
                  {subtitle}
                </p>
                {renderCTA()}
              </div>
            </main>
          </div>
        </div>

        <div className="absolute lg:inset-y-0 lg:right-0 w-full h-full top-0 lg:w-1/2">
          <div className="relative w-full h-full">
            <Image
              className="h-full w-full"
              src={image}
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw"
              style={{
                objectFit: "cover",
              }}
              alt="Cover Movi Autos"
              priority
            />
          </div>
        </div>
        {featuredVehicle ? (
          <div className="invisible lg:visible">
            <FeaturedVehicleComponent
              featuredVehicle={featuredVehicle}
              containerClassName="lg:absolute lg:inset-y-0 lg:right-0 w-full h-full top-0 lg:w-1/2"
            />
          </div>
        ) : null}
      </div>

      {featuredVehicle ? (
        <div className="group relative lg:hidden">
          <FeaturedVehicleComponent
            featuredVehicle={featuredVehicle}
            containerClassName="relative w-full min-h-60 lg:h-0 aspect-w-4 aspect-h-3 sm:aspect-w-4 sm:aspect-h-2 overflow-hidden group-hover:opacity-75 my-7"
          />
        </div>
      ) : null}
    </>
  );
}
