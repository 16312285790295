import Image from "next/image";
import Link from "next/link";

export default function SellYourCarCTA() {
  return (
    <div className="bg-white mt-6">
      <div className="mx-auto max-w-7xl px-4">
        <div className="overflow-hidden rounded-lg bg-orange-500 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                <span className="block">¿Listo para vender tu auto?</span>
                {/* <span className="block">Publicalo.</span> */}
              </h2>
              <p className="mt-3 text-lg leading-6 text-orange-200">
                Publicar tu auto te tomará tan solo unos minutos y sin ningún
                costo. Podés pagar por mayor visibilidad y alcanzar a miles de
                personas interesadas.
              </p>
              <Link href="/vender">
                <span className="mt-3 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-orange-500 shadow hover:bg-orange-50">
                  Publicar
                </span>
              </Link>
            </div>
            <div className="lg:self-center mt-8">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                <span className="block">
                  Consultá nuestros paquetes para negocios
                </span>
                {/* <span className="block">Publicalo.</span> */}
              </h2>
              <p className="mt-3 text-lg leading-6 text-orange-200">
                Si tenés un negocio de compra y venta de autos, te recomendamos
                consultar nuestros paquetes para negocios que te permitirán
                obtener mayor visibilidad y alcance a un menor costo.
              </p>
              <Link href="/pricing?tab=business">
                <span className="mt-3 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-orange-500 shadow hover:bg-orange-50">
                  Ver paquetes
                </span>
              </Link>
            </div>
          </div>
          <div className="aspect-w-5 aspect-h-3 -mt-6 lg:aspect-w-3 lg:aspect-h-4">
            <div className="hidden lg:block translate-x-6 translate-y-12 transform sm:translate-x-16">
              <Image
                src="/cta_screenshot_vertical.png"
                alt="Vendé tu auto en Movi"
                fill
                sizes="40vw"
                style={{
                  objectFit: "cover",
                }}
                className="rounded-md object-left-top"
              />
            </div>
            <div className="block lg:hidden translate-x-6 translate-y-12 transform sm:translate-x-16">
              <Image
                src="/cta_screenshot_horizontal.png"
                alt="Vendé tu auto en Movi"
                fill
                sizes="90vw"
                style={{
                  objectFit: "cover",
                }}
                className="rounded-md object-left-top"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
